<template>
  <v-container fluid class="pa-6 page-container">
    <page-title text="careers"> </page-title>
    <!-- <v-row align="center" justify="space-around" class="input-search">
      <v-col md-4>
        <v-text-field
          @change="searchText"
          prepend-icon="mdi-magnify"
          label="search"
          hide-details="auto"
          v-model="search.searchText"
        ></v-text-field>
      </v-col>
    </v-row> -->
    <!-- <button class="btn-export" @click="downloadExcelsheet">EXCEL</button> -->
    <!-- <button class="btn-export" @click="downloadCsv">CSV</button> -->
    <v-data-table
      class="data-table-class"
      hide-default-footer
      item-key="id"
      sort-by="createdDate"
      :sort-desc="true"
      :headers="headers"
      :items="careersData"
      :header-props="{ 'sort-icon': 'mdi-menu-up' }"
      :server-items-length="totalNumberOfElementsCareers"
      :loading="loading"
      :loading-text="$t('datatable.loading')"
      :options.sync="options"
      :items-per-page="itemsPerPage"
      :page.sync="page"
      checkbox-color="accent"
    >
      <template v-slot:[`item.Name`]="{ item }">
        <span v-if="item.firstName || item.lastName != null">
          {{ item.firstName + " " + item.lastName }}</span
        >
        <span v-else>{{ item.fullName }}</span>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>

      <template v-slot:[`item.downloadCv`]="{ item }">
        <a v-if="item.careerCv" :href="item.careerCv">Download</a>
      </template>
      <template v-slot:[`item.createdDate`]="{ item }">
        <!-- <span>{{ getDate(item) }}</span> -->
        <span>{{
          new Date(item.creationDate).toISOString().substring(0, 10)
        }}</span>
      </template>
    </v-data-table>
    <v-dialog v-model="dialogDelete" content-class="vdialog" max-width="500px">
      <v-card>
        <v-card-title class="text-h5"
          >Are you sure you want to delete this item?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#424242cc" text @click="closeDelete">Cancel</v-btn>
          <v-btn color="#424242cc" text @click="deleteItemConfirm">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-divider />
    <v-row class="justify-end mt-6" v-if="careersData">
      <div class="subtitle-1 mx-2 d-flex align-center">
        <span>{{
          Math.min(
            page * itemsPerPage,
            itemsPerPage + careersData.length,
            careersData.length
          )
        }}</span
        ><span class="mx-1">From</span
        ><span>{{ totalNumberOfElementsCareers }}</span>
      </div>
      <v-pagination
        class="align-self-start"
        v-model="page"
        :length="totalNumberOfPagesCareers"
        :total-visible="7"
      ></v-pagination>
    </v-row>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
    </template>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import App from "../App.vue";

import pageTitle from "../components/page-title.vue";

export default {
  name: "ContactUs",
  components: {
    pageTitle,
    App,
  },
  data() {
    return {
      loading: false,
      itemsPerPage: 10,
      deletedItem: null,
      editedIndex: -1,
      export: null,
      dialogDelete: false,
      page: 1,
      options: {},
      websiteSearch: {
        searchText: null,
      },
      search: {
        searchText: null,
      },
    };
  },

  computed: {
    ...mapGetters("contactUs", [
      "careersData",
      "totalNumberOfElementsCareers",
      "totalNumberOfPagesCareers",
    ]),
    criteria() {
      if (this.search.searchText !== null && this.search.searchText != "") {
        return this.search;
      } else {
        return null;
      }
    },

    headers() {
      return [
        {
          text: "ID",
          value: "id",
        },
        { text: "Email", value: "email" },
        {
          text: "Name",
          value: "name",
        },
        {
          text: "City",
          value: "city",
        },
        {
          text: "Phone",
          value: "phone",
        },
        {
          text: "Address",
          value: "address",
        },
        {
          text: "Education",
          value: "education",
        },
        {
          text: "Qualification",
          value: "qualification",
        },
        {
          text: "Additional Information",
          value: "additionalInformation",
        },
        {
          text: "Download Cv",
          value: "downloadCv",
        },
        {
          text: "Creation Date",
          value: "createdDate",
        },
      ];
    },
  },

  methods: {
    // Load items from store action
    async loadItems() {
      this.loading = true;
      await this.$store.dispatch("contactUs/loadCareers", {
        pageNumber: this.page,
        pageSize: this.itemsPerPage,
        sortingByList: [
          {
            fieldName:
              (this.options?.sortBy && this.options.sortBy[0]) || "createdDate",
            direction: this.options?.sortDesc?.length
              ? this.options?.sortDesc[0]
                ? "DESC"
                : "ASC"
              : "DESC",
          },
        ],
        criteria: this.criteria,
      });
      this.loading = false;
    },

    async downloadExcelsheet() {
      this.export = true;
      const res = await this.$store.dispatch(
        "contactUs/downloadContactUsExcel"
      );
      const url = window.URL.createObjectURL(new Blob([res]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "contact_us.xlsx"); //or any other extension
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(link.href);
      this.exporting = false;
    },
    async downloadCsv() {
      this.export = true;
      const res = await this.$store.dispatch("contactUs/downloadContactUsCsv");
      const url = window.URL.createObjectURL(new Blob([res]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "contact_us.csv"); //or any other extension
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(link.href);
      this.exporting = false;
    },
    deleteItem(item) {
      this.deletedItem = item;

      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.$store.dispatch("contactUs/deletedContactUsItem", this.deletedItem);
      // this.desserts.splice(this.editedIndex, 1);
      this.closeDelete();
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
  async mounted() {
    this.loadItems();
  },

  watch: {
    options: {
      handler() {
        this.loadItems();
      },
      deep: true,
    },

    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/elements/datatable";

// .data-table-class {
//   pointer-events: none;
// }
// .data-table-class:last-child {
//   pointer-events: inherit;
// }
.btn-export {
  border: 1px solid #424242cc;
  background-color: #424242cc;
  color: white;
  width: 150px;
  padding: 5px;
  border-radius: 50px;
  margin-top: 1rem;
  margin-right: 1rem;
}
.input-search {
  margin-bottom: 2rem;
}
.deletedTemplet {
  position: relative;
  z-index: 5;
}
.v-dialog__content {
  align-items: center !important;
}

// .v-data-table {
//   width: 100%;
//   border-spacing: 0;
//   display: flex !important;
//   flex-direction: column !important;
//   justify-content: center !important;
//   align-items: center !important;
//   border: 1px solid !important;
// }
</style>
